<template>
  <div class="list">
    <navbar :title="headline"></navbar>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <notifications></notifications>
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>
            <!-- Start FILTER -->

            <div class="row">
              <div
                class="
                  viewfilter
                  block
                  br-t-l-0 br-t-r-0
                  w-100-p
                  br-t-l-xl-0 br-t-r-xl-0
                  mt-minus-10
                "
              >
                <div class="row">
                    <div class="col-xl-12">
                      <div class="form-group">
                        <input
                          v-model="filters.filterNoteneingabe"
                          class="form-control input"
                          type="text"
                          placeholder=" "
                        />
                        <label>{{ $t("dashboard.scoreinput") }}</label>
                      </div>
                    </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                        <input
                          v-model.trim="filters.filterTeilnehmer"
                          class="form-control input"
                          type="text"
                          placeholder=" "
                        />
                        <label>{{ $t("global.participants") }}</label>
                      </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                        <input
                          v-model="filters.filterCount"
                          class="form-control input"
                          type="text"
                          placeholder=" "
                        />
                        <label>{{ $t("global.numberofrecords") }}</label>
                      </div>
                    </div>
                  </div>
                <filter-search-reset-button/>
              </div>
            </div>

            <!-- Ende FILTER -->
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.grades')"
            ></head-menu>

            <div class="row mb-2">
              <!--
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row">
                  <div class="ml-4 mr-2">
                    <router-link
                      class="btn btn-success"
                      :to="{ name: 'Neue Noten' }"
                    >
                      <font-awesome-icon icon="fa-regular fa-plus" />
                      Neu
                    </router-link>
                  </div>
                  <div class="mr-2">
                    <button class="btn btn-danger" @click="oeffneLoeschenModal">
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-2"
                      />
                      {{ $t("global.delete") }}
                    </button>
                  </div>
                  <div class="mr-2">
                    <button class="btn btn-primary">
                      <font-awesome-icon
                        icon="fa-duotone fa-print"
                        class="mr-2"
                      />
                      Ausgabe
                    </button>
                  </div>
                  <div class="mr-2">
                    <b-dropdown variant="primary">
                      <template slot="button-content">
                        <font-awesome-icon
                          icon="fa-regular fa-cog"
                          class="mr-2"
                        />
                        <em>Aktionen</em>
                      </template>
                      <b-dropdown-item>Noten eingeben</b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
              </div>
               -->
            </div>

            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                  >
                    <template v-slot:head(selected)="row">
                      <b-form-checkbox
                        v-model="row.selected"
                        @input="selectAllRows(row)"
                      ></b-form-checkbox>
                    </template>
                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                  </b-table>
                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">{{ $t("global.nofurtherdatafound") }}</div>
                  </infinite-loading>
                </div>
              </div>
            </div>
            <div id="modal-loeschen" class="modal fade">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <h4>
                      <font-awesome-icon
                        icon="fa-regular fa-warning"
                        class="mr-2 text-warning"
                      />{{ $t("global.deletegrades") }}
                    </h4>
                  </div>
                  <div class="modal-body">
                    {{ $t("global.doyouwantdeleteselectedgrades") }}
                  </div>
                  <div class="modal-footer">
                    <button
                      class="btn btn-default"
                      @click="schliesseLoeschenModal"
                    >
                      {{ $t("global.cancel") }}
                    </button>
                    <button class="btn btn-primary" @click="loeschen">
                      {{ $t("global.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import DatePicker from "@/components/Datepicker";
import Api from "@/Api";
//import { debounce } from "@/utils/Debouncer";
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";

import store from "@/store";
import page from "@/mixins/Page";
import filter from "@/mixins/Filter"
import FilterSearchResetButton from "@/components/Buttons/FilterSearchResetButton";
import InfiniteLoading from "vue-infinite-loading";

import { insertUrlParam, removeUrlParam } from "@/utils/Url";

export default {
  name: "Notenliste",
  components: {
    Navbar,
    InfiniteLoading,
    HeadMenu,
    HeadMenuFilter,
    FilterSearchResetButton,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return this.$t("global.grades");
      },
    };
  },
  mixins: [page, filter],
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
  },
  store,
  data() {
    return {
      //page: 0,
      //notenliste: [],

      //filterTeilnehmer: this.$route.query.filterTeilnehmer,
      //filterNoteneingabe: this.$route.query.filterNoteneingabe,
      //filterCount: null,

      //anzahlProPage: 50,
      //infiniteId: +new Date(),
      sortBy: "noteneingabe",
      sortDesc: false,
      fields: [
        { key: "selected", label: "" },
        { key: "noteneingabe_titel", sortable: true, label: this.$t("dashboard.scoreinput") },
        { key: "teilnehmer.personName", sortable: true, label:  this.$t("global.participants") },
        {
          key: "notendurchschnitt_def",
          sortable: true,
          label: this.$t("global.gradeaveragedef"),
        },
        {
          key: "textwahl_wert.bezeichnung",
          sortable: true,
          label: this.$t("global.valuetextchoice"),
        },
        { key: "textbewertung", sortable: true, label: this.$t("global.textrating") },
        { key: "anzahl_eingaben", sortable: true, label: this.$t("global.numberofinputs") },
      ],
      selected: [],
      selectedIds: [],
    };
  },
  computed: {
    queryParams: function () {
      var params = {};

      if (this.filters.filterTeilnehmer)
        params.filterTeilnehmer = this.setQueryParam(this.filters.filterTeilnehmer);
      if (this.filters.filterNoteneingabe)
        params.filterNoteneingabe = this.setQueryParam(this.filters.filterNoteneingabe);
      if (this.filters.filterCount != "") params.filterCount = this.setQueryParam(this.filters.filterCount);

      return params;
    },

    headline() {
      return "Noten";
    },
  },
  watch: {
    filter: function (val) {
      if (this.showNavbar)
        val ? insertUrlParam("filter", val) : removeUrlParam("filter");
      this.resetLoading(this);
    },
    /*
    filterTeilnehmer: function () {
      this.resetLoading(this);
    },
    filterNoteneingabe: function () {
      this.resetLoading(this);
    },
    filterCount: function () {
      this.resetLoading(this);
    },*/
  },
  created() {
    this.initFilter("noten-liste", "noten/", true);
  },
  mounted() {
    this.$bus.$emit("searchWithFilter");
  },
  methods: {
    details(notenliste) {
      this.$router.push({
        path: "/bildung/noten/" + notenliste.id,
      });
    },
    /* resetLoading: debounce((self) => {
      self.page = 0;
      self.notenliste = [];
      self.infiniteId += 1;
    }, 500), */
    loeschen() {
      Api.delete("noten/", { data: this.selectedIds }).then((response) => {
        this.schliesseLoeschenModal();

        this.listData = this.listData.filter(
          (v) => !response.data.success.includes(v.id)
        );
      });
    },
    schliesseLoeschenModal() {
      $("#modal-loeschen").modal("hide");
    },
    oeffneLoeschenModal() {
      if (this.selectedIds.length != 0) $("#modal-loeschen").modal("show");
      else {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.nogradeselected"),
        });
      }
    },
    /* infiniteHandler($state) {
      Api.get("noten/", {
        params: this.queryParams,
      }).then((response) => {
        if (response.data.length === this.anzahlProPage) {
          this.page += 1;
          this.notenliste.push(...response.data);
          $state.loaded();
        } else {
          this.notenliste.push(...response.data);
          $state.complete();
        }
      });
    },
    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((v) => {
          v.selected = true;
          if (!this.selectedIds.includes(v.id)) {
            this.selectedIds.push(v.id);
          }
        });
      } else {
        this.$refs.selectableTable.items.forEach((v) => {
          v.selected = false;
        });
        this.selectedIds = [];
      }
      this.$forceUpdate();
    },
    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedIds.push(row.item.id);
      } else {
        this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
      }
    }, */
  },
};
</script>

<style>
</style>
